<template>
    <v-app :style="colorVapp">
      <div>
      <onebox_toolbar
      @report_page="fn_getlist_manual"
      ></onebox_toolbar>
      </div>
      <v-content>
          <v-card class="elevation-0">
              <v-list nav dense :color="color.BG" v-if="resolutionScreen >= 500">
              <v-list-item class="text-left">
                <v-list-item-avatar class="text-center pt-1">
                  <v-avatar :color="color.theme" size="35">
                    <v-icon dark size="25">mdi-bullhorn</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="ml-n2 pt-2 pb-1">
                    <v-btn rounded text>
                      <span :style="headerPage">{{
                        $t("reportPrb.headerPage")
                      }}</span>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn  @click="isOpenReportForm = true" rounded 
                  :width="widthBTN" dark color="error" class="btnReport mr-8">
                    <h4>{{ $t('default') === 'en' ? 'Report' : 'แจ้งปัญหา' }}</h4>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
  
              <v-card
                  class="elevation-0"
                  :color="color.BG"
                  id="thiscontainer_report" 
              >
              <v-card-text>
                  <v-container fluid>
                      <!-- LAYOUT MANUALLY -->
                  <v-layout class="pt-6 px-6 pb-8" row  justify-center>
                  <!-- HEADER คู่มือ  -->
                  <v-flex xl12 lg12 row wrap justify-center class="pt-3 pb-3 elevation-1" :style="layoutManualStyle">
                  <!-- <div class="canvas-header-rp px-2" :style="`backgroundColor: white`">
                      <h2>คู่มือการใช้งานเบื้องต้น</h2>
                  </div> -->
                  <v-slide-group
                    v-model="manual_model"
                    class="pa-1 py-n3"
                    active-class="success"
                    show-arrows
                  >
                    <v-slide-item v-for="(item, i) in listmanual" :key="i" :class="`my-6`">
                        <v-card outlined hover  :class="`ml-5 mt-6 cardManual ${i === listmanual.length-1 ? 'mr-2' : ''}`" @click="clickManual(item.link)"
                    style="cursor: pointer; border-radius: 18px; min-width: 240px;"
                        @mouseover="fn_mouseDownHover($event, i, true)" @mouseleave="fn_mouseDownHover($event, i, false)">
                        <v-card-title style="border-radius: 18px;">
                        <v-layout justify-center align-center>
                        <img 
                          width="65"
                          height="65"
                          :class="`${imageManualClass} specifyClass${i}`"
                          :src="require(`@/assets/img/image${imageList[i]}.png`)"
                        />
                      </v-layout>
                        </v-card-title>
                        <v-layout align-center justify-center class="py-3 px-4"  :style="`background-color: ${$vuetify.theme.dark ? '#464646' : '#FAFAFA'};`">
                            <h4  :id="`textLine${i}`" style="font-size: 14px;">
                                {{ $t('default') === 'en' ? item.document_name_eng : item.document_name_th }}
                            </h4>
                        </v-layout>
                    </v-card>
                    </v-slide-item>
                  </v-slide-group>
                  <!-- <v-card outlined hover v-for="(item, i) in listmanual" :key="i" class="ml-5 mt-6 cardManual`" @click="clickManual(item.link)"
                   style="cursor: pointer; border-radius: 18px; min-width: 240px;"
                      @mouseover="fn_mouseDownHover($event, i, true)" @mouseleave="fn_mouseDownHover($event, i, false)">
                      <v-card-title style="border-radius: 18px;">
                      <v-layout justify-center align-center>
                      <img 
                        width="100"
                        height="100"
                        :class="`${imageManualClass} specifyClass${i}`"
                        :src="require(`@/assets/img/image${imageList[i]}.png`)"
                      />
                    </v-layout>
                      </v-card-title>
                      <v-layout align-center justify-center class="py-3 px-4"  :style="`background-color: ${$vuetify.theme.dark ? '#464646' : '#FAFAFA'};`">
                          <h4  :id="`textLine${i}`" style="font-size: 14px;">
                              {{ item.document_name_th }}
                          </h4>
                      </v-layout>
                  </v-card> -->
                  </v-flex>
                  </v-layout>
                      <!--  LAYOUT REPORT -->
                  <v-layout class="pt-2 px-6 pb-8" row>
                    <!-- <v-flex xl6 lg5 md5 pa-lg-2 pa-xl-2 pa-md-2>
                      <v-layout style="overflow: hidden; background-color: transparent;" :column="$vuetify.breakpoint.name === 'md'" :row="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl'" 
                      class="canvas-manage-report elevation-1" :style="styleObject">
                          <v-flex xl7 lg6 md9 class="d-flex justify-center align-center px-4" style="overflow: hidden; position: relative;" :style="`background-color: ${this.$vuetify.theme.dark ? '#363636' : '#ffff'}; `">
                            <v-img
                            style="position: absolute;"
                            :class="imageClass"
                            :src="require('@/assets/img/image12.png')"
                            />
                          </v-flex>
                          <v-flex xl5 lg6 md3 class="d-flex flex-xl-column flex-lg-column justify-center align-center pl-4 pr-4" 
                          style="background-color: #fafafa; border-top-right-radius: 15px; border-bottom-right-radius: 15px;"
                          :style="`background-color: ${this.$vuetify.theme.dark ? '#464646' : '#fafafa'}; `">
                            <p v-if="dataAccountActive['type'] === 'Business' && system_type_run === 'PRODUCTION'" class="font-bold-weight text-xl-subtitle-2 text-center px-md-3 mt-md-2" style="font-weight: bold;" >
                            {{biz_name_th}}
                            </p>
                          <v-btn @mouseover="mouseHover = true" @click="isOpenReportForm = true"
                            @mouseleave="mouseHover = false" rounded 
                            :width="widthBTN" dark :color="color.theme" class="btnReport">
                              <h4>แจ้งปัญหา</h4>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                    </v-flex> -->
                    <v-flex xl12 lg12 md12 pa-lg-2 pa-xl-2 pa-md-2>
                      <v-layout column class="canvas-process-report elevation-1" style="overflow: hidden;">
                        <v-data-table
                          disable-sort
                        :headers="headers"
                        :items="topic_api"
                        hide-default-footer
                        height="650"
                        style="overflow-x: hidden;"
                        class="data-table"
                        fixed-header
                        >
                        <!-- <template slot="header.topic">
                          <th>
                          <v-icon>md-heart</v-icon>
                          <span :style="headerTable">Hot Topic</span>
                          </th>
                        </template>
                        <template v-slot:[`header.description`]>
                          <span :style="headerTable">Description</span>
                        </template> -->
                        <!-- <template v-slot:header="{ props }">
                          <thead>
                            <th v-for="head in props.headers" :key="head.value"  class="header-text-color py-3">{{ head.text }} </th>
                          </thead>
                        </template> -->
                        <template v-slot:[`header.icon`]="{ header }">
                          <span
                                class="font-weight-bold"
                                style="
                                  font-weight: bold;
                                  font-size: 16px;
                                "
                                >{{ $t(header.text) }}</span
                              >
                        </template>
                        <template v-slot:[`header.topic`]="{ header }">
                              <span
                                class="font-weight-bold"
                                style="
                                  font-weight: bold;
                                  font-size: 16px;
                                "
                                >{{ $t(header.text) }}</span
                              >
                        </template>
                        <template v-slot:[`header.description`]="{ header }">
                              <span
                                class="font-weight-bold"
                                style="
                                  font-weight: bold;
                                  font-size: 16px;
                                "
                                >{{ $t(header.text) }}</span
                              >
                        </template>
                        <!-- <template slot="item.topic" slot-scope="{ item }">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span style="font-weight: bold;"> {{ item.topic }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template> -->
                        <template v-slot:item="props">
                              <!-- <tbody v-if="datafolder.lenght > 0"> -->
                          <tr
                            style="cursor: pointer"
                            @click="fn_openChildetree(props.item)"
                          >
                            <td class="text-center">
                              <span style="font-weight: bold;">
                                {{ props.item.description.split('_')[1] }}.
                              </span>
                              <!-- <v-icon>
                                {{ icon_header[0] }}
                              </v-icon> -->
                            </td> 
                            <td class="text-start" style="font-weight: bold; font-size: 16px;"> {{ $t('default') === 'en' ? props.item.topic_en : props.item.topic_th }}</td>
                            <td class="text-center py-2">
                              <v-treeview selected-color="red" :items="itemFilter(props.item)" open-on-click :open="openTreeview">
                              <template v-slot:label="{item}">
                              <v-list-item class="ml-n1 text-center">
                                <v-list-item-content>
                                  <v-list-item-title >
                                    <span  v-if="item.type === 'topic'" class="v-treeview-node__label" :style="labelStyle_text_type">
                                       {{ $t('default') === 'en' ? item.name_en : item.name_th }}
                                      </span>
                                    <div v-else-if="item.type === 'link'">
                                      <v-chip :color="color.theme" dark :disabled="item.link === ''" style="cursor: pointer;" @click="fn_openmanual(item, 'preview', props.item)">
                                        <div>
                                        <v-icon size="18">
                                        mdi-eye
                                      </v-icon>
                                      <span class="v-treeview-node__label py-3" style="font-size: 14px;">
                                        {{ $t('default') === 'en' ? 'Manual' : 'คู่มือใช้งาน' }}
                                      </span>
                                      </div>
                                      </v-chip>
                                      
                                      <v-chip :color="color.theme" dark :disabled="item.link === ''" style="cursor: pointer;" class="ml-3"  @click="fn_openmanual(item, 'download')">
                                        <div>
                                        <v-icon size="18">
                                          mdi-download
                                        </v-icon>
                                        <span class="v-treeview-node__label py-3" style="font-size: 14px;">
                                        {{ $t('default') === 'en' ? 'Download' : 'ดาวน์โหลดคู่มือ' }}
                                        </span>
                                      </div>
                                      </v-chip>
                                    </div>
                                    <!-- SPECIAL  -->
                                    <div v-else-if="item.type === 'text'">
                                      <v-chip :color="color.theme" dark style="cursor: pointer;" class="ml-3" @click="fn_openmanual(item, 'preview_descript')">
                                        <div>
                                        <v-icon size="18">
                                          mdi-book-information-variant
                                        </v-icon>
                                        <span class="v-treeview-node__label py-3" style="font-size: 14px;">
                                          {{ $t('default') === 'en' ? 'More information' : 'ดูข้อมูลเพิ่มเติม' }}
                                        </span>
                                      </div>
                                      </v-chip>
                                    </div>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            </v-treeview>
                            </td>
                          </tr>
                        </template>
                        </v-data-table>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  </v-container>
              </v-card-text>
              </v-card>
            </v-list>
            <v-list v-else nav :color="color.BG" class="mb-n2">
              <v-list-item class="text-left">
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
                <v-list-item-avatar class="text-center">
                  <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-bullhorn</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="ml-n2">
                  <span :style="headerPageMobile">{{$t("reportPrb.headerPage")}}</span>
                </v-list-item-title>
              </v-chip>
            </v-list-item>
            <v-card
                  class="elevation-0"
                  :color="color.BG"
                  id="thiscontainer_report" 
              >
              <v-card-text>
                  <v-container fluid>
                      <!-- LAYOUT MANUALLY -->
                  <v-layout  row class="pb-8" justify-center align-start>
                  <!-- HEADER คู่มือ  -->
                  <v-flex md12 sm12 xs12 row justify-center class="elevation-1" :style="layoutManualStyle">
                    <v-flex  style="border-radius: 15px;" :class="`${i === listmanual.length -1 ? 'sm6 xs12' : 'sm6 xs12'}`" v-for="(item, i) in listmanual" :key="i">
                      <v-card outlined  class="px-3 py-3" @click="clickManual(item.link)" @mouseover="fn_mouseDownHover($event, i, true)" @mouseleave="fn_mouseDownHover($event, i, false)"
                      :style="`border-top-right-radius: ${i === 0 ? '15px' : '0px'}; border-top-left-radius: ${i === 0 ? '15px' : '0px'}; 
                      border-bottom-left-radius: ${i === listmanual.length - 1 ? '15px' : ''}; border-bottom-right-radius: ${i === listmanual.length - 1 ? '15px' : ''};
                      min-height: 80px; max-height: 80px; border-color: transparent;`">
                        <div class="d-flex flex-row  align-center">
                          <div class="p-3">
                            <img 
                            :width="$vuetify.breakpoint.name === 'sm' ? '60' : '40'"
                            :height="$vuetify.breakpoint.name === 'sm' ? '60' : '40'"
                            :class="`${imageManualClass} specifyClass${i}`"
                            :src="require(`@/assets/img/image${imageList[i]}.png`)"
                          />
                          </div>
                          <v-layout class="px-4">
                              <span  :id="`textLine${i}`" style="font-size: 12px; font-weight: bold;">
                                {{ $t('default') === 'en' ? item.document_name_eng : item.document_name_th }}
                              </span>
                          </v-layout>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-flex>
                  </v-layout>
                      <!--  LAYOUT REPORT -->
                  <v-layout class="pt-6 pb-8" row justify-center>
                    <v-flex sm12 xs12 row class="canvas-manage-report-device elevation-1" style="background-color: transparent;">
                      <v-flex sm8 xs8 :style="`background-color: ${this.$vuetify.theme.dark ? '#1e1e1e' : '#ffff'}; `" class="d-flex flex-column align-center justify-center py-sm-4 px-4">
                        <!-- <span v-if="dataAccountActive['type'] === 'Business' && system_type_run === 'PRODUCTION'" class="text-center font-weight-bold" style="font-size: 12px; font-weight: bold;">
                          {{biz_name_th}} <br /> 
                        </span> -->
                        <v-btn @mouseover="mouseHover = true" @click="isOpenReportForm = true"
                            @mouseleave="mouseHover = false" rounded :small="$vuetify.breakpoint.name === 'xs'"
                            :width="widthBTN" dark color="error" class="btnReport mt-5">
                              <span style="font-weight: bold;">
                                {{ $t('default') === 'en' ? 'Report' : 'แจ้งปัญหา' }}
                              </span>
                            </v-btn>
                      </v-flex>
                      <v-flex sm4 xs4 class="d-flex justify-center align-center px-sm-1" style="overflow: hidden;" :style="`background-color: ${this.$vuetify.theme.dark ? '#464646' : '#fafafa'}; `">
                        <img 
                        :style="imageSizereport_device"
                        :src="require('@/assets/img/image12.png')"
                        />
                      </v-flex>
                      <!-- <v-layout style="overflow: hidden;" column 
                      class="elevation-1" :style="styleObject">
                          <v-flex sm3 xs3 class="d-flex justify-center align-center pa-4" 
                          style="background-color: #fafafa; border-top-right-radius: 15px; border-bottom-right-radius: 15px;">
                            <p class="font-bold-weight text-xl-subtitle-2 text-center mr-sm-8" style="font-weight: bold;" >
                            {{biz_name_th}} <br /> ({{ taxid_biz }})
                            </p>
                          <v-btn @mouseover="mouseHover = true" @click="isOpenReportForm = true"
                            @mouseleave="mouseHover = false" rounded :small="$vuetify.breakpoint.name === 'xs'"
                            :width="widthBTN" dark :color="color.theme" class="btnReport ml-sm-8">
                              <span>
                                แจ้งปัญหา
                              </span>
                            </v-btn>
                          </v-flex>
                          <v-flex sm9 xs9 class="d-flex justify-center align-center px-4" style="overflow: hidden; position: relative;">
                            <v-img
                            style="position: absolute;"
                            :class="imageClass"
                            :src="require('@/assets/img/image12.png')"
                            />
                          </v-flex>
                        </v-layout> -->
                    </v-flex>
                  </v-layout>
  
                  <v-layout row justify-center>
                    <v-flex class="elevation-1" sm12 xs12 style="width: 100%; border-radius: 15px; overflow: hidden;">
                      <v-data-table
                        disable-sort
                        :headers="headers"
                        :items="topic_api"
                        hide-default-footer
                        height="400"
                        style="overflow-x: hidden;"
                        class="data-table"
                        fixed-header
                        mobile-breakpoint
                        >
                        <template v-slot:[`header.icon`]="{ header }">
                              <span
                                class="font-weight-bold"
                                style="
                                  font-weight: bold;
                                  font-size: 14px;
                                "
                                >{{ $t(header.text)  }}</span
                              >
                        </template>
                        <template v-slot:[`header.topic`]="{ header }">
                              <span
                                class="font-weight-bold"
                                style="
                                  font-weight: bold;
                                  font-size: 14px;
                                "
                                >{{ $t(header.text) }}</span
                              >
                        </template>
                        <template v-slot:[`header.description`]="{ header }">
                              <span
                                class="font-weight-bold"
                                style="
                                  font-weight: bold;
                                  font-size: 14px;
                                "
                                >{{ $t(header.text) }}</span
                              >
                        </template>
                        <template v-slot:item="props">
                              <!-- <tbody v-if="datafolder.lenght > 0"> -->
                          <tr
                            @click="fn_openChildetree(props.item)"
                            style="cursor: pointer"
                          >
                            <td class="text-center"> 
                              <span style="font-weight: bold;">
                                {{ props.item.description.split('_')[1] }}.
                              </span>
                            </td> 
                            <td class="text-start py-3" style="font-size: 12px; font-weight: bold;"> {{ $t('default') === 'en' ? props.item.topic_en : props.item.topic_th }}</td>
                            <td class="text-center">
                              <v-treeview :items="itemFilter(props.item)" open-on-click :open="openTreeview">
                                
                              <template v-slot:label="{item}">
                              <v-list-item class="ml-n1 text-center">
                                <v-list-item-content>
                                  <v-list-item-title >
                                    <span  v-if="item.type === 'topic'" class="v-treeview-node__label" :style="labelStyle_text_type" @click="fn_openmanual(item, 'preview')">
                                      {{ $t('default') === 'en' ? item.name_en : item.name_th }}
                                      </span>
                                    <div v-else-if="item.type === 'link'" >
                                      <v-chip v-if="item.link !== ''" dark :color="color.theme" small style="cursor: pointer;" @click="fn_openmanual(item, 'preview', props.item)">
                                        <div>
                                        <v-icon :size="$vuetify.breakpoint.name === 'sm' ?18 : 16">
                                        mdi-eye
                                      </v-icon>
                                      <span class="v-treeview-node__label py-3" v-if="$vuetify.breakpoint.name === 'sm'" style="font-size: 12px;"  >
                                        {{ $t('default') === 'en' ? 'Manual' : 'คู่มือใช้งาน' }}
                                      </span>
                                      </div>
                                      </v-chip>
                                      <v-chip v-if="item.link !== ''" dark :color="color.theme" small style="cursor: pointer;" class="ml-3" @click="fn_openmanual(item, 'download')">
                                        <div>
                                        <v-icon :size="$vuetify.breakpoint.name === 'sm' ?18 : 16">
                                          mdi-download
                                        </v-icon>
                                        <span class="v-treeview-node__label py-3" v-if="$vuetify.breakpoint.name === 'sm'" style="font-size: 12px;" >
                                          {{ $t('default') === 'en' ? 'Download' : 'ดาวน์โหลดคู่มือ' }}
                                        </span>
                                      </div>
                                      </v-chip>
                                    </div>
                                    <!-- SPECIAL  -->
                                    <div v-else-if="item.type === 'text'">
                                      <v-chip small dark :color="color.theme" style="cursor: pointer;" class="ml-3" @click="fn_openmanual(item, 'preview_descript')">
                                        <div>
                                        <v-icon :size="$vuetify.breakpoint.name === 'sm' ?18 : 16">
                                          mdi-book-information-variant
                                        </v-icon>
                                        <span class="v-treeview-node__label py-3" v-if="$vuetify.breakpoint.name === 'sm'" style="font-size: 12px;">
                                          {{ $t('default') === 'en' ? 'More information' : 'ดูข้อมูลเพิ่มเติม' }}
                                        </span>
                                      </div>
                                      </v-chip>
                                    </div>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            </v-treeview>
                            </td>
                          </tr>
                        </template>
                        </v-data-table>
                    </v-flex>
                  </v-layout>
                  </v-container>
              </v-card-text>
  
                  
              </v-card>
            </v-list>
          </v-card>
      </v-content>
  
  
      <v-dialog 
      v-model="showdialog_text"
      scrollable
      persistent
      max-width="1200"
      >
      <v-card>
        <v-card-title>
          <v-icon :color="color.theme" dense dark size="35" class="pr-3">mdi-bullhorn</v-icon>
          {{$t('default') === 'en' ? 'Detail' : 'รายละเอียด'}}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-3 px-6">
            <v-layout column>
              <p style="font-size: 18px; font-weight: 800; line-height: 1.5;">
                {{ $t('reportPrb.descript_taxbox_l1') }} <br />
                -> {{ $t('reportPrb.descript_taxbox_l2') }} <br />
              </p>
              
              <div v-if="isloadingImage" class="text-center">
                <v-progress-circular 
                  indeterminate
                  size="23"
                  :color="color.theme"
                />
              </div>
              <div v-else class="text-center">
                <img
                style="max-width: 70vw !important; max-height: 50vh !important;"
                :src="imageIframe"
                alt="preview"
                class="modal-contentc testImage mt-4"
              />
              </div>
  
  
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="(showdialog_text = !showdialog_text)"
              color="red"
              outlined>{{ $t('default') === 'en' ? 'Close' : 'ปิด' }}</v-btn>
          </v-card-actions>
        </v-card>
  
      </v-dialog>
  
  
  
  
      <previewmanual 
        :show="opendialog_manual"
        :filedata="file_data_manual"
        @closepreviewfile="opendialog_manual = false"
      />
      <!-- <previewpublicfile
        :show="openpreviewpublicfile"
        :filedata="currentfile"
        :parentfolder="parentfolder"
        @closepreviewfile="openpreviewpublicfile = false"
        @openpreviewfile="openpreviewpublicfile = true"
      ></previewpublicfile> -->
      <!-- <div v-if="isOpenReportForm">
        
      </div> -->
      <form_report 
        :show="isOpenReportForm"
        :biz_nam="bizName"
        :topicCase="topic_case"
        :username_account="dataCitizenProfile"
        @closedialog="isOpenReportForm = false"
        />
    </v-app>
  </template>
  
  <script>
  const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
  const previewmanual = () => import("../components/optional/dialog-preview-manual");
  const form_report = () => import("../components/optional/dialog-report-form.vue");
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { mapState, mapGetters } from "vuex";
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
  
  components: {
    onebox_toolbar,
    form_report,
    previewmanual,
  },
   data: () => ({
      isExpanedTreeview: false,
      isloadingImage: false,
      imageIframe: "",
      showdialog_text: false,
      opendialog_manual: false,
      openTreeview: [],
      user_profile: {},
      file_data_manual: {},
      system_type_run: "",
      bizName: "",
      manual_model: true,
      isOpenReportForm: false,
      manualHover: false,
      mouseHover: false,
      page: 1,
      biz_name_th: "",
      biz_name_en: "",
      taxid_biz: "",
      // pageCount: 1,
      size: 5,
      icon_header: [
        "mdi-book-information-variant", 
        "mdi-account-group" , 
        "mdi-account-cog",
        "mdi-account-badge-horizontal",
        "mdi-file-search",
        "mdi-file-download",
        "mdi-account-multiple-minus",
        "mdi-file-document-box-minus",
      ],
      topic_case: [],
      listmanual: [],
      imageList: [2, 4, 3, 9, 6, 1],
      headers:[
        { text: 'reportPrb.no_header_table', value: 'icon', align: 'center', width: '10%'},
        { text: 'reportPrb.hottopic_header', value: 'topic', align: 'start', width: '60%'},
        { text: 'reportPrb.description_header', value: 'description', align: 'center', width: '30%'},
      ],
      hot_topic:[
          { topic: 'วิธีการใช้งาน Onebox', description: 'description_1'},
          { topic: 'การตั้งค่าสิทธิ์ Permission', description: 'description_2'},
          { topic: 'การตั้งค่าสิทธิ์ การเพิ่มค่าบริษัท (แผนก, พนักงาน)', description: 'description_3'},
          { topic: 'หาเอกสาร ETAX INV ไม่พบ', description: 'description_4'},
          { topic: 'ไม่สามารถ Download ETAX INV', description: 'description_5'},
          { topic: 'ไม่มีสิทธิ์ในการลบเอกสาร Inbox/Outbox', description: 'description_6'},
          { topic: 'การลบไฟล์ Inbox/Outbox', description: 'description_7'},
          { topic: 'ค้นหาไฟล์ Taxbox ไม่เจอ', description: 'description_8'},
          
      ],
      items_treeview: [
        [{ id: 1, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }],
        [{ id: 2, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }],
        [{ id: 3, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }],
        [
          { id: 4, name: 'วิธีการใช้งาน', icon: "", type: "topic",
            children: [ { id: 5, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" } ]
          },
          { id: 6, name: 'การค้นหา ETAX INV', icon: "", type: "topic", 
            children: [{ id: 7, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }]
          },
          { 
            id: 8, name: 'การตั้ง Business เริ่มต้น', icon: "", type: "topic",
            children: [{ id: 9, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }]
          }
        ],
        [{ id: 10, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }],
        [{ id: 11, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }],
        [{ id: 12, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }],
        [{ id: 13, name: 'ดาวโหลดคู่มือ', icon: "", type: "link", link: "" }]
      ],
      topic_api: [],
      description_api: [],
      databusiness: [
          {
              id: 1,
              sender_name: "TEST",
              count_active_file: 20
          },
          {
              id: 2,
              sender_name: "MAI",
              count_active_file: 20
          },
          {
              id: 3,
              sender_name: "MAITEST",
              count_active_file: 20
          },
          {
              id: 4,
              sender_name: "MAISS",
              count_active_file: 20
          },
          {
              id: 5,
              sender_name: "sss",
              count_active_file: 20
          },
          {
              id: 6,
              sender_name: "ff",
              count_active_file: 20
          },
          {
              id: 7,
              sender_name: "gg",
              count_active_file: 20
          },
          {
              id: 8,
              sender_name: "aa",
              count_active_file: 20
          },
          {
              id: 9,
              sender_name: "ww",
              count_active_file: 20
          },
          {
              id: 10,
              sender_name: "rr",
              count_active_file: 20
          },
          {
              id: 11,
              sender_name: "tt",
              count_active_file: 20
          }
      ]
   }),
   computed: {
      ...mapState(["username", "authorize", "account_active", "color", "storage_usage"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataStorageUsage",
        "dataStorageMax",
      ]),
      colorVapp() {    
        return "background:" + this.color.BG + ";";
      },
      headerPage() {
        return (
          "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;"
        );
      },
      headerTable() {
        return ( "color:" + this.color.theme + ";" + "font-weight: bold; font-size:15px;" );
      },
      //สีอักษรของ headerpage ของ mobile
      headerPageMobile() {
        return "color:" + this.color.theme + ";" + "font-size: 16px; font-weight: 700; line-height: 24px;";
      },
      headerChipPage() {
        return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;"
      },
      layoutManualStyle () {
          return (
              // "backgroundColor:"+ this.color.BG + ";"+
              `backgroundColor: ${this.$vuetify.theme.dark ? '#363636' : 'white'};`+
              // "border: 1px solid "+ this.color.theme + ";"+
              "borderRadius: 15px;"+
              "position: relative;"
          )
      },
      layoutManualStyle_v2 () {
          return (
              "backgroundColor: white;"+
              // "border: 1px solid "+ this.color.theme + ";"+
              "borderRadius: 15px;"+
              "position: relative;"+
              "margin-left: 5px;"
          )
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      styleObject () {
          return {
          '--bgF-color': this.convertColor(localStorage.getItem('color')),
          '--bgR-color': this.color.theme
        };
      },
      widthBTN () {
        if(this.$vuetify.breakpoint.name === 'xl'){
          return '140'
        } else if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'sm') {
          return '130'
        } else {
          return '100'
        }
      },
      imageSizereport_device () {
        if(this.$vuetify.breakpoint.name === 'sm'){
          return `width: 200px; height: 140px; objectFit: contain;`
        } else {
          return `width: 200px; height: 140px; objectFit: cover;`
        }
      },
      imageClass () {
        if(this.mouseHover){
          return 'imageReport animatedScale'
        } else {
          return 'imageReport'
        }
      },
      imageManualClass () {
        if(this.manualHover){
          return 'imageManualReport animatedManualScale'
        } else {
          return 'imageManualReport'
        }
      },
      labelStyle_text_type () {
        return `cursor: pointer; font-weight: bold; text-align: center; font-size: ${this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs' ? '12px' : '16px'};`
      },
      labelStyle_link_type () {
        return `font-weight: bold; cursor: pointer; color: ${this.color.theme}; text-decoration: underline; font-size: ${this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs' ? '12px' : '12px'};`
      },
      pageCount: {
        get() {
          let l = this.databusiness.length;
          let s = this.size;
          console.log("MAII ", Math.ceil(l / s))
          return Math.ceil(l / s);
        },
        set(newName) {
          return newName;
        },
      },
      
   },
   watch: {
  
   },
   methods: {
      fn_openChildetree (itemRow) {
        if(this.openTreeview.length === 0){
          let _childrenTreeView = []
          let index_Description = itemRow.description.split('_')[1]
          this.description_api[index_Description-1].forEach((itemChec) => {
            _childrenTreeView.push(itemChec.hasOwnProperty("children"))
          })
  
          this.description_api[index_Description-1]
          .forEach((element, index) => {
            if(_childrenTreeView[index])
              this.openTreeview.push(element.id)
          })
        } else {
          this.openTreeview = []
        }
        
      },
      async setimageviewer() {
        this.isloadingImage = true
        let payload = {
          user_id: this.dataUsername,
          file_id: this.system_type_run === "PRODUCTION" ? "1848e574cb5653e3dafba9aa53188c3a" : "369b537ba4161084f880de3593a10edd",
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
            payload,
            {
              headers: { Authorization: auth.code },
              withCredentials: false,
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            console.log("Image response ", response.data)
            const buffer = Buffer.from(response.data, "base64");
            let u8 = new Uint8Array(buffer);
            let b64encoded = btoa(
              [].reduce.call(
                new Uint8Array(buffer),
                function(p, c) {
                  return p + String.fromCharCode(c);
                },
                ""
              )
            );
            let mimetype = "image/png";
            this.imageIframe = "data:" + mimetype + ";base64," + b64encoded;
            setTimeout(() => {
            this.isloadingImage = false
            }, 1000);
            
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async fn_openmanual (Item_,type, index) {
        console.log("Index manual ", this.topic_api)
        console.log("Manual ", this.description_api)
        console.log("HLELO ", Item_)
        if(type === 'download'){
          window.open(Item_.link)
        } else if (type === 'preview') {
          let prepareIndex = index.description.split('_')[1]
          this.opendialog_manual = true
          let header_topicc = ""
          if(this.description_api[prepareIndex-1][0].name === "คู่มือการใช้งาน"){
            header_topicc = this.$t('default') === 'en' ? this.topic_api[prepareIndex-1].topic_en :  this.topic_api[prepareIndex-1].topic_th
          } else {
            header_topicc = this.$t('default') === 'en' ? this.description_api[prepareIndex-1][0].name_en : this.description_api[prepareIndex-1][0].name_th
          }
          this.file_data_manual = {
            file_id: "",
            user_id: "Share",
            link_: Item_.link,
            topic_header: header_topicc
          }
        } else {
          this.showdialog_text = true
          await this.setimageviewer() 
          
          
          
        }
        // window.open(link)
        console.log("Link  ", link)
      },
      async fn_testdownload () {
        let auth = await gbfGenerate.generateToken();
        let payload = {
          case_ticket_id: "2f647f35a47704cff1c29572123eb43e"
        }
        this.axios({
          method: "POST",
          url:
          process.env.VUE_APP_SERVICE_ADMIN + "/api/v1/get_case_ticket_attach",
          data: payload,
          headers: { Authorization: auth.code },
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("SDD F", response)
          let headers_res = response.headers;
          let blob = new Blob([response.data], {
            type: headers_res["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "ทดสอบดาวน์โหลด";
          link.click();
        })
        .catch((err) => {
          console.log("Error Catch ", err)
        })
      },
      itemFilter (item) {
        let ArrayDescription = this.description_api[new Number(item.description.split('_')[1])-1]
        return ArrayDescription
      },
      fn_mouseDownHover (e, specIndex, anim) {
        if(anim){
          document.getElementsByClassName(`specifyClass${specIndex}`)[0].classList.add('animatedManualScale')
          //document.querySelector(`#textLine${specIndex}`).className="Animunderlined"; 
        } else {
          document.getElementsByClassName(`specifyClass${specIndex}`)[0].classList.remove('animatedManualScale')
        }
        
      },
      async fn_getTopic () {
        await this.axios.get(
          process.env.VUE_APP_SERVICE_ADMIN +
              "/api/admin/json_configs/hot_topics_manual_json"
        ).then((response) => {
          this.topic_api = response.data.data.json_config_data.topics
          //response.data.data.json_config_data.treeview[0].push({id: 99, name: "SS", type: "link", link: "", icon: ""})
          this.description_api = response.data.data.json_config_data.treeview
          console.log("TESS ", this.description_api)
        })
        .catch((err) => {
            console.log(err)
        })
      },
      async fnGetManual() {
        await this.axios
          .get(
            process.env.VUE_APP_SERVICE_ADMIN +
              "/api/admin/get_user_manual?type=basic"
          )
          .then((res) => {
            if (res.data.status == "OK") {
              this.listmanual = res.data.result;
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("manual.manualfail"),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: this.$t("manual.manualfail"),
            });
          });
      },
      fn_getlist_manual (list_manual) {
          let prepareData = list_manual
          console.log("")
          prepareData.map((item, index) => {
              console.log("ITEMM ", item.document_name_th.split(' '))
              Object.assign(item, {image: `@/assets/img/image${this.imageList[index]}.png`})
              if(!item.document_name_th.search('VDO')){
                  Object.assign(item, {icon: "mdi-play-circle-outline"})
              } else {
                  Object.assign(item, {icon: "mdi-book"})
              }
          })
         this.listmanual = prepareData
         console.log("LUST MANUAL ", this.listmanual)
      },
      clickManual(url) {
        window.open(url)
      },
      // HEX TO RGBA
      convertColor(hexcode) {
        let hex = hexcode;
        let opacity = "0.5";
        // Convert each hex character pair into an integer
        let red = parseInt(hex.substring(1, 3), 16);
        let green = parseInt(hex.substring(3, 5), 16);
        let blue = parseInt(hex.substring(5, 7), 16);
  
        // Concatenate these codes into proper RGBA format
        let rgba = ` rgba(${red}, ${green}, ${blue}, ${opacity})`;
        return rgba;
      },
      fn_getInforBizByTaxid () {
        console.log("THIS DataAccountActive ", this.dataAccountActive )
        let payload = {
            // taxid: "0105538031526" // Config ไว้ก่อนน
            taxid: this.dataAccountActive.taxid,
          };
          console.log("payload", payload);
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_ADMIN + "/api/get_data_business",
              payload
            )
            .then((res) => {
              console.log("REPOOSS ", res.data.result)
              console.log("RESPONSE ", res.data.result[0].business)
              console.log("BIIZ ", this.dataBusinessProfile)
              this.biz_name_th = res.data.result[0].business.full_name_th
              this.taxid_biz = res.data.result[0].business.taxid
            })
      },
      async fn_getTopicCase() {
        let auth = await gbfGenerate.generateToken();
        console.log("AUTHH ", auth)
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/v1/get_cases",
            "",
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.topic_case = response.data.data
          })
      },
      
   },
   mounted () {
    console.log("DDA ", this.dataCitizenProfile)
    this.system_type_run = process.env.VUE_APP_ENVIRONMENT;
    // if(this.dataAccountActive["type"] === "Business" && this.system_type_run === "PRODUCTION" ){
    //   this.bizName = this.dataAccountActive.business_info.first_name_th
    //   this.fn_getInforBizByTaxid()
    // } 
    this.fn_getTopic()
    this.fn_getTopicCase()
      //this.fnGetManual();
   }
  
  
  }
  </script>
  
  <style scoped>
  .v-treeview-node__root {
    background-color: red;
  }
  .v-treeview-node__root:focus {
   background: none;
  }
  .v-treeview-node__root:active {
      background-color: white !important;
  }
  
  .v-treeview-node__root:focus::before {
    opacity: 0;
  }
  
  .canvas-manage-report {
    width: 100%;
    height: 400px;
    border-radius: 15px;
    background-color: white;
  }
  
  .canvas-manage-report-device {
    width: 100%;
    /* height: 100px; */
    border-radius: 15px;
    overflow: hidden;
    background-color: white;
  }
  .canvas-process-report {
    width: 100%;
    height: 650px;
    border-radius: 15px;
    background-color: white;
  }
  #thiscontainer_report {
    height: calc(101vh - 170px);
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow-x: hidden;
    /* max-width: 100%; */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  #borderradius {
    border-radius: 15px;
  }
  #borderradius_card {
    border-radius: 26px;
  }
  .primary {
    background-color: #1279bc !important;
    border-color: #1279bc !important;
  }
  .canvas-header-rp {
      position: absolute;
      /* top: 3%; */
      top: -4%;
      left: 2%;
  }
  
  .canvas-header-sec-rp {
      position: absolute;
      top: -4%;
      left: 4%;
  }
  
  
  .cardManageReport {
      height: 350px;
      background-color: red;
  }
  .cardProgessReport {
      height: 350px;
  }
  .cardManual {
      border-radius: 18px;
  }
  .icon-man {
      color: white;
  }
  .cardManual .layoutBody {
      height: 100px;
      border-radius: 15px;
      background-color: var(--bgF-color);
      transition: .5s;
  }
  
  .cardManual:hover .layoutBody{
      background-color: var(--bgR-color);
  }
  
  .cardManual:hover .icon-man{
      transform: scale(1.2);
  }
  .cardManual:focus-within .layoutBody{
      background-color: var(--bgR-color)
  }
  .cardManual:focus-within .icon-man{
      transform: scale(1.2);
  }
  .imageReport {
    transform: scale(0.8);
    position: absolute;
    transition: .5s;
    object-fit: contain;
  }
  .imageManualReport {
    transform: scale(1);
    transition: .5s;
    object-fit: contain;
  }
  .animatedScale {
    transform: scale(.9);
  }
  .animatedManualScale {
    transform: scale(1.2);
  }
  
  .Animunderlined {
    position: relative;
    text-decoration: none;
  }
  
  .Animunderlined::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -5px;
    left: 0;
    background-color: rgb(214, 214, 214);
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .Animunderlined:hover::before {
    transform: scaleX(1);
  }
  
  .canvas-process-report .data-table::-webkit-scrollbar {
    display: none;
  }
  .header-text-color {
    background-color: #FAFAFA;
  }
  .v-data-table.v-data-table__wrapper table tbody tr td {
    font-weight: bold;
  }
  i.v-icon notranslate material-icons theme--light  {
    background-color: red;
  }
  ::v-deep .v-treeview-node__root {
    color: transparent !important;
  }
  
  </style>